/* eslint-disable max-lines-per-function */
import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { ActivityStatus, ApiCallOptions } from "@maxxton/cms-mxts-api";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { TimeDisplayPattern, TimeslotStatus } from "./ActivityTimeslot.enum";
import { getActivitySections, getActivityTimeSlots } from "../../page/activityPlanner/activityPlanner.util";
import { useDispatch, useSelector } from "react-redux";

import { ActionType } from "../../../redux/actions";
import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { ActivityPlannerActionType } from "../../../redux/actions/activityPlannerAction";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { State } from "../../../redux";
import { TicketingTypes } from "../../page/activityPlanner/activityPlanner.enum";
import { WidgetOptions } from "./";
import { WidgetType } from "../../widget";
import { cloneDeep } from "lodash";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getHideWidgetClass } from "../../../components/utils";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";

export interface TimeslotWidgetProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    activity?: Activity;
    handleTimeSelection?: (oldResourceActivityDetailsId: number, newResourceActivityDetailsId: number) => void;
    widgetType: WidgetType;
}
interface TimeslotStoreProps {
    dynamicFilter?: DynamicFilter;
}

interface ActivityTimeslot {
    startTime: string;
    endTime: string;
    resourceActivityDetailsId: number;
    resourceId: number;
    status: TimeslotStatus;
}

export type TimeslotWidgetBaseProps = TimeslotWidgetProps & TimeslotStoreProps;

// eslint-disable-next-line max-lines-per-function
const ActivityTimeslotWidget = (props: TimeslotWidgetBaseProps) => {
    const {
        options: { timeDisplayPattern, enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor, showPublishedTimeslots, showTextStyleDropdown, localizedTimeslotWidgetOptions },
        context,
        activity,
        handleTimeSelection,
    } = props;
    const { currentLocale, site } = context;
    const dynamicFilter: DynamicFilter = useSelector((state: State) => state.dynamicFilter || {});
    const { reservedResourceSubjectQuantities } = useSelector((state: State) => state.activityPlannerState || {});
    const { startdate, enddate, resortids, resourceActivityDetailsId, resourceActivityDetailsIds, resortActivityId, resourceid, showMainActivityOnPage, selectedActivities } = dynamicFilter;
    const [activityTimeslots, setactivityTimeslots] = React.useState<ActivityTimeslot[]>([]);
    const [numberOfSections, setNumberOfSections] = React.useState<number>(1);
    const [env, setEnv] = React.useState<ApiCallOptions>();
    const [selectedTime, setSelectedTime] = React.useState<number | undefined>(activity ? activity.resourceActivityDetailsId : resourceActivityDetailsId);
    const [selectedDropdownTime, setSelectedDropdownTime] = React.useState<ActivityTimeslot>();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
    const [disableWidget, setDisableWidget] = React.useState<boolean>(true);
    const hideWidget = getHideWidgetClass(props.options, disableWidget);
    const dispatchAction = useDispatch();
    React.useEffect(() => {
        const fetchTimeslots = async () => {
            let startDate = startdate;
            let endDate = enddate;

            if (!startDate) {
                const currentDate = moment().clone();
                startDate = currentDate.format(DATE_FORMAT.DEFAULT);
                endDate = currentDate.add(1, "year").format(DATE_FORMAT.DEFAULT);
            } else if (startDate && !endDate) {
                endDate = moment(startDate).clone().add(1, "year").format(DATE_FORMAT.DEFAULT);
            }
            const env = await getMxtsEnv(context, context.currentLocale.code);
            setEnv(env);
            let activityTimeslots;
            if (activity) {
                // Get list of timeslot for a section in case of CRP
                activityTimeslots = await getActivityTimeSlots({
                    env,
                    startDate: activity?.day || "",
                    endDate: activity?.day || "",
                    resortId: activity.resourceActivity.resortId,
                    context,
                    resortActivityId: activity.resourceActivity.resortActivityId,
                    resourceId: activity.resourceId,
                    showPublishedTimeslots,
                });
                setactivityTimeslots(activityTimeslots);
            } else {
                // For page level
                activityTimeslots = await getActivityTimeSlots({
                    env,
                    startDate: moment(startDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS),
                    endDate: moment(endDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS),
                    resortId: resortids?.[0],
                    context,
                    resortActivityId,
                    resourceId: resourceid,
                    showPublishedTimeslots,
                });
                setactivityTimeslots(activityTimeslots);
            }
            if (activityTimeslots.length) {
                if (resourceActivityDetailsId) {
                    const selectedTimeslot = activityTimeslots.find((timeslot) => Number(timeslot.resourceActivityDetailsId) === Number(resourceActivityDetailsId)) || activityTimeslots[0];
                    if (selectedTimeslot.status === TimeslotStatus.AVAILABLE) {
                        onTimeSelection(selectedTimeslot);
                    } else {
                        for (const timeslot of activityTimeslots) {
                            if (timeslot.status === TimeslotStatus.AVAILABLE) {
                                onTimeSelection(timeslot);
                                break;
                            }
                        }
                    }
                } else if (dynamicFilter.resourceActivityDetailsIds?.length) {
                    for (const timeslot of activityTimeslots) {
                        if (dynamicFilter.resourceActivityDetailsIds.includes(timeslot.resourceActivityDetailsId) && timeslot.status === TimeslotStatus.AVAILABLE) {
                            onTimeSelection(timeslot);
                        }
                    }
                } else {
                    // For CRP and deep linking without detailsId
                    const selectedTimeslot = activityTimeslots.find((timeslot) => timeslot.resourceActivityDetailsId === selectedTime);
                    if (!selectedTimeslot || selectedTimeslot.status !== TimeslotStatus.AVAILABLE) {
                        for (const timeslot of activityTimeslots) {
                            if (timeslot.status === TimeslotStatus.AVAILABLE) {
                                onTimeSelection(timeslot);
                                break;
                            }
                        }
                    }
                }
            }
            if (!dynamicFilter.resourceid && props.widgetType === "page") {
                const resourceIds = [...new Set(activityTimeslots.map((timeslot) => timeslot.resourceId))];
                dispatchAction({
                    type: ActionType.FilterChange,
                    filter: dynamicFilterType.resourceid,
                    payload: {
                        resourceid: resourceIds[0],
                    },
                });
            }
            if (props.widgetType === "resultsPanel") {
                const activitySections = await getActivitySections(context, env, activity);
                const numberOfSections = new Set(activitySections.map((section) => section.resourceId)).size;
                setNumberOfSections(numberOfSections);
            }
            const currentTimeObj = activityTimeslots?.find((timeslot) => timeslot.resourceActivityDetailsId === Number(selectedTime));
            if (currentTimeObj) {
                setSelectedDropdownTime(currentTimeObj);
            }
        };
        fetchTimeslots();
    }, [startdate, enddate]);

    const onTimeSelection = async (timeslot: ActivityTimeslot) => {
        if (activity && handleTimeSelection) {
            // This is for CRP
            handleTimeSelection(activity.resourceActivityDetailsId, timeslot.resourceActivityDetailsId);
        }
        setSelectedTime(timeslot.resourceActivityDetailsId);
        dispatchAction({
            type: ActionType.FilterChange,
            filter: dynamicFilterType.setResourceActivityDetailsId,
            payload: {
                resourceActivityDetailsId: timeslot.resourceActivityDetailsId,
            },
        });
        if (dynamicFilter.subject && reservedResourceSubjectQuantities?.length) {
            const subjects = cloneDeep(dynamicFilter.subject);
            subjects.forEach((_, subjectId) => {
                subjects.set(subjectId, 0);
            });
            dispatchAction({
                type: ActionType.activityPlanner,
                actionType: ActivityPlannerActionType.SET_SUBJECT_QUANTITIES,
                payload: { reservedResourceSubjectQuantities: undefined },
            });
            dispatchAction({
                type: ActionType.FilterChange,
                filter: dynamicFilterType.subjects,
                payload: { subject: subjects },
            });
        }
        let mxtsEnv = env;
        if (!mxtsEnv) {
            mxtsEnv = await getMxtsEnv(context, context.currentLocale.code);
            setEnv(mxtsEnv);
        }
        const filteredActivityDetailsIds = resourceActivityDetailsIds?.filter(
            (resourceActivityDetailsId) => !selectedActivities?.find((selectedActivity) => selectedActivity.resourceActivityDetailsId === Number(resourceActivityDetailsId))
        );
        const selectedResourceActivities = await context.mxtsApi
            .getResourceActivitiesDetails(mxtsEnv, {
                resourceActivityDetailsIds: resourceActivityDetailsIds?.length && filteredActivityDetailsIds ? filteredActivityDetailsIds : [timeslot.resourceActivityDetailsId],
            })
            .then((result) => result.content.filter((resourceActivity) => resourceActivity.status !== ActivityStatus.CANCELLED));

        const isGroupTicketActivity = selectedResourceActivities.find(
            (selectedResourceActivity) => selectedResourceActivity.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_GROUP
        );
        dispatchAction({
            type: ActionType.FilterChange,
            filter: dynamicFilterType.setSelectedActivities,
            payload: { selectedActivities: selectedActivities?.length && isGroupTicketActivity ? [...selectedActivities, ...selectedResourceActivities] : selectedResourceActivities },
        });
        setSelectedDropdownTime(timeslot);
    };

    const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: props.options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
    const isDateSelected = !!activity || !!(dynamicFilter.startdate || dynamicFilter.enddate); // Check the start and end dates checks only on the page level, not on the CRP.
    const shouldShowTimeslots = activityTimeslots.length === 1 ? props.options.showSingleTimeslot : activityTimeslots.length > 1;
    const dropdownToggleTag = showTextStyleDropdown ? "span" : "div";
    const additionalDropdownText =
        (localizedTimeslotWidgetOptions && showTextStyleDropdown && (
            <a className="timeslot-dropdown-text">
                {getLocalizedContent({ site, currentLocale, localizedContent: localizedTimeslotWidgetOptions, keys: ["additionalTextForDropdown"] })?.additionalTextForDropdown}
            </a>
        )) ||
        "";

    if (hideWidget === null) {
        return null;
    }

    if (shouldShowTimeslots && (!activity?.showMainActivity || !(numberOfSections > 1)) && !showMainActivityOnPage && isDateSelected) {
        // Disable the timeslot widget for the main activity in CRP and on the page level when the bowling table is being displayed.
        return (
            <React.Fragment>
                <LocalizedTitleAndLabel
                    localizedTitle={localizedWidgetTitle}
                    enableWidgetTitle={enableWidgetTitle}
                    useTitleHeadings={useTitleHeadings}
                    styleWidgetTitle={styleWidgetTitle}
                    className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                />
                <div className="timeslot-wrapper">
                    {timeDisplayPattern === TimeDisplayPattern.GRID_FORMAT ? (
                        activityTimeslots?.map((timeslot) => (
                            <div
                                onClick={() => {
                                    onTimeSelection(timeslot);
                                }}
                                className={`timeslot ${Number(selectedTime) === timeslot.resourceActivityDetailsId ? "timeslot-active" : ""} ${
                                    timeslot.status === TimeslotStatus.CANCELLED ? "timeslot-cancelled" : timeslot.status === TimeslotStatus.SOLD_OUT ? "timeslot-sold-out" : ""
                                }`}
                                key={timeslot.resourceActivityDetailsId}
                            >
                                <span className="timeslot__time">{`${timeslot.startTime} - ${timeslot.endTime}`}</span>
                            </div>
                        ))
                    ) : (
                        <React.Fragment>
                            <Dropdown
                                isOpen={isDropdownOpen}
                                toggle={() => {
                                    setIsDropdownOpen(!isDropdownOpen);
                                }}
                                className="dropdown"
                            >
                                <DropdownToggle tag={dropdownToggleTag} caret>
                                    {showTextStyleDropdown && <FontAwesome name="clock-o mr-1" />}
                                    {`${selectedDropdownTime?.startTime} - ${selectedDropdownTime?.endTime} `}
                                    {additionalDropdownText}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {activityTimeslots?.map((timeslot) => (
                                        <DropdownItem
                                            onClick={() => {
                                                onTimeSelection(timeslot);
                                            }}
                                            key={timeslot.resourceActivityDetailsId}
                                            className={`${
                                                timeslot.status === TimeslotStatus.CANCELLED ? "timeslot-cancelled" : timeslot.status === TimeslotStatus.SOLD_OUT ? "timeslot-sold-out" : ""
                                            }`}
                                        >{`${timeslot.startTime} - ${timeslot.endTime}`}</DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
    return null;
};
export default ActivityTimeslotWidget;
