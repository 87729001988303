import * as React from "react";

import { FormSpec, localized } from "../../../form-specs";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { TypesearchContainerWidgetSpec, Widget } from "../../";

import ActivityTimeslotWidget from "./ActivityTimeslot";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { SharedWidgetParams } from "../../widget";
import { TimeDisplayPattern } from "./ActivityTimeslot.enum";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends LocalizedTitleOptions {
    contentTypeSelector?: boolean;
    contentType?: string;
    timeDisplayPattern?: TimeDisplayPattern;
    showSingleTimeslot?: boolean;
    showPublishedTimeslots: boolean;
    showTextStyleDropdown?: boolean;
    localizedTimeslotWidgetOptions?: LocalizedTimeslotWidgetOptions[];
}

interface LocalizedTimeslotWidgetOptions extends LocalizedContentBase {
    additionalTextForDropdown: string;
}

interface WidgetOptionsFormParams {
    widgetOptionsFormId: string;
}

const widgetOptionsForm = ({ widgetOptionsFormId }: WidgetOptionsFormParams): FormSpec<WidgetOptions> => ({
    id: widgetOptionsFormId,
    name: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityTimeslotWidget"),
    pluralName: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityTimeslotWidgets"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "timeDisplayPattern",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "timeDisplayPattern"),
                                type: "select",
                                optionList: [
                                    {
                                        value: TimeDisplayPattern.DROPDOWN_FORMAT,
                                        label: getI18nLocaleObject(namespaceList.admin, "dropdownFormat"),
                                    },
                                    {
                                        value: TimeDisplayPattern.GRID_FORMAT,
                                        label: getI18nLocaleObject(namespaceList.admin, "gridFormat"),
                                    },
                                ],
                            },
                            {
                                variable: "showSingleTimeslot",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "showSingleTimeslot"),
                                type: "checkbox",
                                default: "false",
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            {
                                variable: "showPublishedTimeslots",
                                label: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "showPublishedTimeslots"),
                                default: true,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            ...titleStylingOptions<WidgetOptions>(),
                            {
                                variable: "showTextStyleDropdown",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "showTextStyleDropdown"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.timeDisplayPattern === TimeDisplayPattern.DROPDOWN_FORMAT,
                            },
                            localized({
                                variable: "localizedTimeslotWidgetOptions",
                                visible: (options: WidgetOptions) => options.timeDisplayPattern === TimeDisplayPattern.DROPDOWN_FORMAT && !!options.showTextStyleDropdown,
                                tabContent: [
                                    {
                                        variable: "additionalTextForDropdown",
                                        label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "additionalTextForDropdown"),
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
            ],
        },
    ],
});

export const sharedActivityTimeslotWidget = ({ widgetId, widgetType, widgetOptionsFormId }: SharedWidgetParams): TypesearchContainerWidgetSpec<WidgetOptions> => ({
    id: widgetId,
    type: widgetType,
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityTimeslotWidget"),
    description: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityTimeslotWidgetDescription"),
    optionsForm: widgetOptionsForm({ widgetOptionsFormId }),
    defaultOptions: (): WidgetOptions => ({
        showPublishedTimeslots: true,
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        return <ActivityTimeslotWidget options={widget.options} context={context} widgetType={widgetType} />;
    },
    container: false,
});
